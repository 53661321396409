import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { SchedulingInspectionContainerProps } from "../../interfaces/containers";
import ScheduleInspection from "../../pages/Sell/ScheduleInspection/ScheduleInspection";
import { getScheduling } from "../../actions/scheduling";
import { postTransactionConfirmation, cleanTransaction, postRejectInspection } from "../../actions/transaction";
import { getIsValidDocuments, cleanIsValidDocuments } from "../../actions/documents";

function mapStateToProps(state: SchedulingInspectionContainerProps) {
    return{
        user: state.auth.user,
        transactionConfirmation: state.transaction.transactionConfirmation,
        scheduling: state.scheduling.scheduling,
        isValidDocuments: state.documents.valid,
        rejectInspection: state.transaction.rejectInspection
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getScheduling,
        postTransactionConfirmation,
        cleanTransaction,
        getIsValidDocuments, 
        cleanIsValidDocuments,
        postRejectInspection
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleInspection);