import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { BuyProps } from "../../../interfaces/containers";
import ScheduleInspection from "../../../pages/Buy/ScheduleInspection/ScheduleInspection";
import { setStep, postTransaction, cleanPostTransaction, setCleanAll, setValidation as setValidationReducer } from "../../../actions/buy";
import { getIsValidDocuments, cleanIsValidDocuments } from "../../../actions/documents";

function mapStateToProps(state: BuyProps) {
    return{
        user: state.auth.user,
        publication: state.buy.publication,
        step: state.buy.step,
        method: state.buy.method,
        transactionFetching: state.buy.transactionFetching,
        creditSimulation: state.buy.creditSimulation,
        financing: state.buy.financing,
        valid: state.documents.valid,
        inspectionPoints: state.buy.inspectionPoints
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        setStep,
        postTransaction,
        cleanPostTransaction,
        setCleanAll,
        getIsValidDocuments, 
        cleanIsValidDocuments,
        setValidationReducer
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleInspection);