import React from "react";
import { RouteObject } from "../../interfaces/navigation";
import AdditionalCosts from '../../containers/Buy/AdditionalCosts/EntryPointContainer';
import ScheduleInspection from '../../containers/Buy/ScheduleInspection/EntryPointContainer';
import PickUpPoint from '../../containers/Buy/PickUpPoint/EntryPointContainer';
import CreditRequest from '../../containers/Buy/CreditRequest/EntryPointContainer';
import Financing from '../../containers/Buy/Financing/EntryPointContainer';
import Summary from '../../containers/Buy/Summary/EntryPointContainer';

const EntryPoint = React.lazy(() => import("../../containers/Buy/BuyContainer"));

const buySubRoutes: RouteObject[] = [
    {
        title: "Buy",
        path: "credit_request",
        component: <CreditRequest/>,
        protected: true
    },
    {
        title: "Buy",
        path: "financing",
        component: <Financing/>,
        protected: true
    },
    {
        title: "Buy",
        path: "summary",
        component: <Summary/>,
        protected: true
    },
    {
        title: "Buy",
        path: "schedule_inspection",
        component: <ScheduleInspection/>,
        protected: true
    },
    {
        title: "Buy",
        path: "pickup_point",
        component: <PickUpPoint/>,
        protected: true
    },
    {
        title: "Buy",
        path: "additional_costs",
        component: <AdditionalCosts/>,
        protected: true
    }
]

export const buyRoutes: RouteObject[] = [
    {
        title: "Buy",
        path: "/buy",
        component: <EntryPoint/>,
        subRoutes: buySubRoutes,
        protected: true
    }
];