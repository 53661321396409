import { memo, useEffect, useState } from 'react';
import styles from './pickUpPoint.module.scss';
import { useNavigate } from "react-router-dom";
import { capitalize, nextDatesAvailable } from '../../../helpers/app';
import { useToast } from '../../../helpers/hooks';
import BuyModal from '../../../components/BuyModal';
import ButtonShared from '../../../components/Button';
import TextFieldShared from '../../../components/TextField';
import ToggleButtonShared from '../../../components/ToggleButton/ToggleButton';
import Modal from '../../../components/Modal';
import Slider from '../../../components/Slider';
import { images } from '../../../constants/images';
import { BuyScheduleInspectionProps } from '../../../interfaces/pages';
import { Radio } from '@mui/material';

const PickUpPoint = ({
    setStep,
    step,
    method,
    getPickUpPoints,
    setInspectionPoint,
    inspectionPoints
}: any) => {
    const [pickUpPoint, setPickUpPoint] = useState(inspectionPoints.selected ?? "virtual");
    const navigate = useNavigate();
    const handlePressBack = () => {
        setStep(step - 1);
        if (method === "cash") {
            navigate(`/buy/additional_costs`);
        }
        if (method === "credit") {
            navigate("/buy/summary");
        }
    }
    const handleSend = () => {
        setStep(step + 1);
        setInspectionPoint(pickUpPoint)
        if (pickUpPoint === 'virtual') {
            navigate('/buy/schedule_inspection');
        }
        if (pickUpPoint === 'in_person') {
            getPickUpPoints()
            navigate('/buy/schedule_inspection');

        }
    }

    return (
        <>
            <BuyModal title="Agendar inspección" onBack={handlePressBack} step={step} method={method}>
                <p className={styles.title}>
                    <span>Elige como realizar la inspección del vehículo antes de completar tu compra. Puedes optar por una inspección presencial o virtual según lo que mejor se adapte a tus necesidades.</span>
                </p>
                <div className={styles.pickUpPoint}>

                    <div onClick={() => setPickUpPoint('virtual')} className={styles.pickUpPointContainer}>
                        <div className={styles.pickUpPointText}>
                            <Radio
                                checked={pickUpPoint === 'virtual'}
                                onChange={() => setPickUpPoint('virtual')}
                                value={pickUpPoint}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'Inspección virtual' }}
                                className={styles.radio}
                                sx={{
                                    color: "#7100FF",
                                    '&.Mui-checked': {
                                        color: "#7100FF",
                                    },
                                }}
                            />
                            <p className={styles.titlePickUpPoint}>Inspección virtual</p>
                            <p className={styles.descriptionPickUpPoint}>Un agente Linzer inspecciona el auto en la casa del vendedor y te conecta por videollamada.</p>
                        </div>
                    </div>
                    <div onClick={() => setPickUpPoint('in_person')} className={styles.pickUpPointContainer}>
                        <div className={styles.pickUpPointText}>
                            <Radio
                                checked={pickUpPoint === 'in_person'}
                                onChange={() => setPickUpPoint('in_person')}
                                value={pickUpPoint}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'Inspección presencial' }}
                                className={styles.radio}
                                sx={{
                                    color: "#7100FF",
                                    '&.Mui-checked': {
                                        color: "#7100FF",
                                    },
                                }}
                            />
                            <p className={styles.titlePickUpPoint}>Inspección presencial</p>
                            <p className={styles.descriptionPickUpPoint}>Visita un punto de inspección donde el vendedor llevará el auto para que lo revises en persona.</p>
                        </div>
                    </div>
                </div>
                <ButtonShared
                    onPress={handleSend}
                    title="Continuar"
                    primary
                    loading={inspectionPoints.fetching === "loading"}
                    className={styles.submitButton}
                />

            </BuyModal>
        </>
    )
}

export default memo(PickUpPoint);