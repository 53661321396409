import styles from "./buyModal.module.scss";
import { IconButton } from "@mui/material";
import { BuyModalProps } from "../../interfaces/components";
import Icon from '@mdi/react';
import { mdiChevronLeft } from '@mdi/js';
import Stepper from "../../containers/Buy/Stepper/StepperContainer";

const BuyModal = ({
    title,
    children, 
    step = 1,
    method = 'cash',
    onBack
}: BuyModalProps) => {

    return(
        <div className={styles.buyModal}>
            <Stepper />
            <div className={styles.buyModalContainer}>
                <div className={styles.headerModal}>
                    <IconButton aria-label="Atras" className={styles.backButton} onClick={() => onBack()}>
                        <Icon path={mdiChevronLeft} className={styles.icon} title="Atras" />
                    </IconButton>
                    <p className={styles.title}>{title}</p>
                    <p className={styles.stepperNumber}>{step}/{(method === "cash") ? "3" : "6"}</p>
                </div>
                <div className={styles.buyBody}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default BuyModal;