import { memo, useEffect, useState } from 'react';
import styles from './additionalCosts.module.scss';
import { Skeleton } from '@mui/material';
import { useNavigate } from "react-router-dom";
import BuyModal from '../../../components/BuyModal';
import Button from '../../../components/Button';
import { addPointsInText } from '../../../helpers/app';
import { AdditionalCostsProps } from '../../../interfaces/pages';
import { useToast } from '../../../helpers/hooks';

const AdditionalCosts = ({
    setStep,
    step,
    method,
    publication,
    getAdditionalExpensesTransaction,
    additionalCost,
    setCleanAll
}: AdditionalCostsProps) => {
    const toast = useToast();
    const navigate = useNavigate();
    const [totalExpenses, setTotalExpenses] = useState<number>(0);

    const nextStep = () => {
        setStep(step + 1);
        if(method === "cash"){
            navigate("/buy/pickup_point");
        }
        if(method === "credit"){
            navigate("/buy/summary");
        }
    }

    const handlePressBack = () => {
        setStep(step - 1);
        if(method === "cash"){
            setCleanAll();
            navigate(`/publication/${publication?.data?._id}`);
        }
        if(method === "credit"){
            navigate(`/buy/financing`);
        }
    }

    useEffect(() => {
        if(additionalCost?.fetching === "failure"){
            toast("Ha ocurrido un error al mostrar los gastos operacionales");
            if(method === "cash"){
                setCleanAll();
                navigate(`/publication/${publication?.data?._id}`);
            }
            if(method === "credit"){
                navigate(`/buy/financing`);
            }
        }
        if(additionalCost?.fetching === "success"){
            setTotalExpenses((parseInt(publication?.data?.other_prices?.offer_price ?? publication?.data?.price ?? 0) + parseInt(additionalCost?.data?.transfer_legal_registration) + parseInt(additionalCost?.data?.transaction_cost) + parseInt(additionalCost?.data?.vehicle_transfer)));
        }
    }, [additionalCost?.fetching]);

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.style.overflowY = "auto";
        if(method === "cash"){
            getAdditionalExpensesTransaction("cash", (parseInt(publication?.data?.other_prices?.offer_price ?? publication?.data?.price ?? 0)).toString());
        }
        if(method === "credit"){
            getAdditionalExpensesTransaction("financing", (parseInt(publication?.data?.other_prices?.offer_price ?? publication?.data?.price ?? 0).toString()));
        }
    }, []);

    return (
        <BuyModal title={method === "cash" ? "Resumen de compra" : "Gastos operacionales"} onBack={handlePressBack} step={step} method={method}>
            <div className={styles.additionalCost}>
                <p className={`${styles.title} ${styles.costs}`}>
                    Cargos administrativos propios de la compra:
                </p>
                <div className={styles.info}>
                    <p>
                        <span className={styles.name}>Transferencia / Inscripción legal</span>
                        {
                            additionalCost?.fetching === "loading"
                            ?
                                <Skeleton animation="wave" variant="rounded" className={styles.skeleton} />
                            :
                                <span className={styles.value}>${addPointsInText(additionalCost?.data?.transfer_legal_registration)}</span>
                        }
                    </p>
                    <hr className={styles.line} />
                    <p>
                        <span className={styles.name}>Costo de transacción</span>
                        {
                            additionalCost?.fetching === "loading"
                            ?
                                <Skeleton animation="wave" variant="rounded" className={styles.skeleton} />
                            :
                                <span className={styles.value}>${addPointsInText(additionalCost?.data?.transaction_cost)}</span>
                        }
                    </p>
                    <hr className={styles.line} />
                    <p>
                        <span className={styles.name}>Traslado vehículo</span>
                        {
                            additionalCost?.fetching === "loading"
                            ?
                                <Skeleton animation="wave" variant="rounded" className={styles.skeleton} />
                            :
                                <span className={styles.value}>${addPointsInText(additionalCost?.data?.vehicle_transfer)}</span>
                        }
                    </p>
                    <hr className={styles.line} />
                    <p className={styles.bold}>
                        <span className={styles.name}>Total gastos operacionales</span>
                        {
                            additionalCost?.fetching === "loading"
                            ?
                                <Skeleton animation="wave" variant="rounded" className={styles.skeleton} />
                            :
                                <span className={styles.value}>${addPointsInText(parseInt(additionalCost?.data?.transfer_legal_registration) + parseInt(additionalCost?.data?.transaction_cost) + parseInt(additionalCost?.data?.vehicle_transfer))}</span>
                        }
                    </p>
                </div>
                {
                    method === "cash" &&
                    <>
                        <p className={`${styles.title} ${styles.pay}`}>
                            Desglose de tu pago al contado:
                        </p>
                        <div className={styles.info}>
                            <p>
                                <span className={styles.name}>Total gastos operacionales</span>
                                {
                                    additionalCost?.fetching === "loading"
                                    ?
                                        <Skeleton animation="wave" variant="rounded" className={styles.skeleton} />
                                    :
                                        <span className={styles.value}>${addPointsInText(parseInt(additionalCost?.data?.transfer_legal_registration) + parseInt(additionalCost?.data?.transaction_cost) + parseInt(additionalCost?.data?.vehicle_transfer))}</span>
                                }
                            </p>
                            <hr className={styles.line} />
                            <p>
                                <span className={styles.name}>Precio del vehículo</span>
                                {
                                    additionalCost?.fetching === "loading"
                                    ?
                                        <Skeleton animation="wave" variant="rounded" className={styles.skeleton} />
                                    :
                                        <span className={styles.value}>${addPointsInText(publication?.data?.other_prices?.offer_price ?? publication?.data?.price ?? 0)}</span>
                                }
                            </p>
                            <hr className={styles.line} />
                            <p className={`${styles.total} ${styles.bold}`}>
                                <span className={styles.name}>Total contado</span>
                                {
                                    additionalCost?.fetching === "loading"
                                    ?
                                        <Skeleton animation="wave" variant="rounded" className={styles.skeleton} />
                                    :
                                        <span className={styles.value}>${addPointsInText(totalExpenses)}</span>
                                }
                            </p>
                        </div>
                    </>
                }
                <div className={styles.submitButton}>
                    <Button
                        onPress={nextStep}
                        title="Siguiente"
                        primary
                        disabled={additionalCost?.fetching === "loading"}
                        className={styles.button}
                    />
                </div>
            </div>
        </BuyModal>
    )
}

export default memo(AdditionalCosts);