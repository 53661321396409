import { memo, useEffect, useState } from 'react';
import styles from './summary.module.scss';
import { useNavigate } from "react-router-dom";
import BuyModal from '../../../components/BuyModal';
import Button from '../../../components/Button';
import { addPointsInText } from '../../../helpers/app';
import { SummaryProps } from '../../../interfaces/pages';

const Summary = ({
    setStep,
    step,
    method,
    financing,
    creditSimulation,
    additionalCost
}: SummaryProps) => {
    const navigate = useNavigate();
    const [operationalCosts, setOperationalCosts] = useState<number>(0);

    const nextStep = () => {
        setStep(step + 1);
        navigate("/buy/pickup_point");
    }

    const handlePressBack = () => {
        setStep(step - 1);
        navigate(`/buy/additional_costs`);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        setOperationalCosts(parseInt(additionalCost?.data?.transfer_legal_registration) + parseInt(additionalCost?.data?.transaction_cost) + parseInt(additionalCost?.data?.vehicle_transfer))
    }, [])

    return (
        <BuyModal title="Resumen" onBack={handlePressBack} step={step} method={method}>
            <div className={styles.summary}>
                <p className={styles.title}>
                    Precio del vehículo
                </p>
                <p className={styles.priceCar}>${addPointsInText(financing?.price)}</p>
                <hr className={styles.line} />
                <div className={styles.item}>
                    <p className={styles.label}>Desglose de tu pago al contado</p>
                    <p>
                        <span className={styles.name}>Total gastos operacionales</span>
                        <span className={styles.value}>${addPointsInText(operationalCosts)}</span>
                    </p>
                    <hr className={styles.line} />
                    <p>
                        <span className={styles.name}>Valor pie</span>
                        <span className={styles.value}>${addPointsInText(financing?.footValue)}</span>
                    </p>
                    <hr className={styles.line} />
                    <p>
                        <span className={styles.name}>Total Vale Vista</span>
                        <span className={styles.value}>${addPointsInText(Math.round(financing?.footValue) + operationalCosts)}</span>
                    </p>
                </div>
                <hr className={styles.line} />
                <div className={styles.item}>
                    <p className={styles.label}>Resumen de tu pago financiado</p>
                    <p>
                        <span className={styles.name}>Monto a financiar crédito</span>
                        <span className={styles.value}>${addPointsInText(creditSimulation?.data?.simulation_response?.financing?.total_finance)}</span>
                    </p>
                    <hr className={styles.line} />
                    <p>
                        <span className={styles.name}>Plazo</span>
                        <span className={styles.value}>{addPointsInText(financing?.fees)} meses</span>
                    </p>
                    <hr className={styles.line} />
                    <p>
                        <span className={styles.name}>Valor cuota*</span>
                        <span className={styles.value}>${addPointsInText(creditSimulation?.data?.simulation_response?.financing?.installment_value)}</span>
                    </p>
                    <hr className={styles.line} />
                    <p>
                        <span className={styles.name}>CAE</span>
                        <span className={styles.value}>{addPointsInText(creditSimulation?.data?.simulation_response?.financing?.cae)}%</span>
                    </p>
                    {
                        financing?.creditType === "intelligent" && 
                        <>
                            <hr className={styles.line} />
                            <p>
                                <span className={styles.name}>VFMG</span>
                                <span className={styles.value}>${addPointsInText(creditSimulation?.data?.simulation_response?.financing?.vfmg)}</span>
                            </p>
                        </>
                    }
                </div>
                {
                    false &&
                    <>
                        <hr className={styles.line} />
                        <p className={styles.message}>
                            <span>*El valor cuota es referencial y puede variar conforme a las condiciones del crédito a solicitar. Para mayor información revisa nuestros</span>
                            <span className={styles.link} onClick={() => navigate("/terms")}>Términos y Condiciones</span>
                        </p>
                    </>
                }
            </div>
            <Button
                onPress={nextStep}
                title="Siguiente"
                primary
                disabled={false}
                className={styles.submitButton}
            />
        </BuyModal>
    )
}

export default memo(Summary);