import ReactDOM from 'react-dom/client';
import './index.module.scss';
import './styles/themeColors.scss';
import Root from './components/Root';
import { store } from "./config/store";
import { datadogRum } from '@datadog/browser-rum';

const dev = process.env.NODE_ENV === 'development';
if(!dev){
    datadogRum.init({
        applicationId: '08a7a02a-5ad9-4ee7-ad2b-b372e2396680',
        clientToken: 'pub38f1031f92eb00a6b2199144d869877f',
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'datadoghq.com',
        service: 'linze-web',
        env: process.env.REACT_APP_NODE_ENV,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: '1.0.1',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    });
    datadogRum.startSessionReplayRecording();
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if(window?.Cypress){
    window.store = store;
}

root.render(<Root/>);