import { useEffect, useState } from "react";
import styles from "./salary.module.scss";
import { optionsContractType, optionsIngressType, optionsAntiquity } from "../../../../constants/documentation";
import { useToast } from "../../../../helpers/hooks";
import { SalaryProps } from "../../../../interfaces/components";
import Button from "../../../../components/Button";
import TextFieldShared from "../../../../components/TextField/TextField";
import ToggleButtonShared from "../../../../components/ToggleButton/ToggleButton";
import { optionsRent } from "../../../../constants/Buy";

const Salary = ({
    salary,
    user,
    editUserRequest,
    fetchingEdit,
    cleanEditUser,
    getIsValidDocuments,
    types
}: SalaryProps) => {
    const toast = useToast();
    const [contractType, setContractType] = useState("");
    const [rentType, setRentType] = useState("");
    const [ingressType, setIngressType] = useState("");
    const [antiquity, setAntiquity] = useState(0);
    const [liquidRent, setLiquidRent] = useState("");

    const onSaveData = () => {
        if (
            user?.labor_data.type_contract !== contractType
            || user?.labor_data.liquid_income !== parseInt(liquidRent)
            || user?.labor_data.type_income !== ingressType
            || user?.labor_data.seniority !== antiquity
        ) {
            editUserRequest({
                ...user,
                labor_data: {
                    liquid_income: Number(liquidRent.replaceAll(".", "")),
                    type_contract: contractType,
                    type_income: ingressType,
                    seniority: antiquity
                }
            });
        }
    };

    useEffect(() => {
        if (fetchingEdit === "failure") {
            toast("Ha ocurrido un error al actualizar su liquidación de sueldo");
            cleanEditUser();
        }
        if (fetchingEdit === "success") {
            toast("Felicitaciones! Los cambios han sido guardados.");
            getIsValidDocuments();
            cleanEditUser();
        }
    }, [fetchingEdit]);

    useEffect(() => {
        if (user?.labor_data) {
            setLiquidRent(user?.labor_data.liquid_income ? user?.labor_data.liquid_income.toString() : "0");
            setContractType(user?.labor_data.type_contract);
            setIngressType(user?.labor_data.type_income);
            setAntiquity(user?.labor_data.seniority);
            setRentType(user?.labor_data?.type_rent)
        }
    }, [user]);

    return (
        <div className={styles.salaryContainer}>
            <div className={styles.salaryDataContainer}>
                <div className={`${styles.item} ${styles.liquidRent}`}>
                    <p className={styles.title}>¿Cuál es tu renta líquida?</p>
                    <div className={styles.itemContent}>
                        <TextFieldShared
                            variant="borderless"
                            name="liquidRent"
                            onChange={(value: any) => setLiquidRent(value)}
                            className={styles.liquidRentInput}
                            value={liquidRent}
                        />
                    </div>
                    <span>*Recomendamos renta líquida mayor o igual a $700.000</span>
                </div>
                <div className={`${styles.item} ${styles.contractType}`}>
                    <p className={styles.title}>¿Cómo recibes ingresos?</p>
                    <div className={styles.itemContent}>
                        <ToggleButtonShared
                            type="group"
                            value={ingressType}
                            onPress={(value: any) => value && setIngressType(value)}
                            exclusive
                        >
                            {optionsIngressType}
                        </ToggleButtonShared>
                    </div>
                </div>
                <div className={`${styles.item} ${styles.ingressType}`}>
                    <p className={styles.title}>¿Qué tipo de contrato tienes?</p>
                    <div className={styles.itemContent}>
                        <ToggleButtonShared
                            type="group"
                            value={contractType}
                            onPress={(value: any) => value && setContractType(value)}
                            exclusive
                        >
                            {optionsContractType}
                        </ToggleButtonShared>
                    </div>
                </div>
                <div className={`${styles.item} ${styles.antiquity}`}>
                    <p className={styles.title}>¿Cuál es la antiguedad laboral?</p>
                    <span>(Número de cotizaciones consecutivos)</span>
                    <div className={`${styles.itemContent} ${styles.optionsAntiquity}`}>
                        <TextFieldShared
                            variant="borderless"
                            name="seniority"
                            value={antiquity}
                            onChange={(value: any) => setAntiquity(value)}
                            className={styles.input}
                            icon=''
                        />
                    </div>
                </div>
                <div className={`${styles.item} ${styles.ingressType}`}>
                    <p className={styles.title}>¿Qué tipo de contrato tienes?</p>
                    <div className={styles.itemContent}>
                        <ToggleButtonShared
                            type="group"
                            value={rentType}
                            onPress={(value: any) => value && setRentType(value)}
                            exclusive
                        >
                            {optionsRent}
                        </ToggleButtonShared>
                    </div>
                </div>
                <div className={styles.buttons}>
                    <Button
                        onPress={onSaveData}
                        title="Guardar Cambios"
                        primary
                        className={styles.button}
                        disabled={!salary || types.fetching === "loading" || types.fetching === "failure"}
                        loading={fetchingEdit === "loading"}
                    />
                </div>
            </div>

        </div>
    )
}
export default Salary;