import { useEffect, useState, useMemo } from 'react';
import styles from './myAccount.module.scss';
import { Skeleton } from '@mui/material';
import { MyAccountProps } from '../../../interfaces/components';
import { images } from '../../../constants/images';
import { civilOptions, genderOptions, residence } from '../../../constants/profile';
import countries from '../../../constants/countries.json'
import { formatRut, validateRut } from '../../../helpers/app';
import { useToast } from "../../../helpers/hooks";
import ProfileCard from "../../../components/ProfileCard";
import TextFieldShared from "../../../components/TextField/TextField";
import SelectShared from "../../../components/Select/Select";
import ButtonShared from "../../../components/Button/Button";
import DatePickerShared from '../../../components/DatePicker/DatePicker';
import Modal from '../../../components/Modal/Modal';
import Compressor from 'compressorjs';
import PhoneInput from '../../../components/PhoneInput/PhoneInput';
import CheckIcon from '@mui/icons-material/Check';
import ModalValidationDocuments from '../../../components/ModalValidationDocuments/ModalValidationDocuments';
import { googleLogout } from '@react-oauth/google';

const MyAccount = ({
    user,
    token,
    getRegions,
    regions,
    getCities,
    cities,
    getCommunes,
    communes,
    patchResidence,
    fetchingPatchResidence,
    editUserReducer,
    editUserRequest,
    cleanProfileFetching,
    postImage,
    postUserImage,
    cleanPostImage,
    deleteUser,
    deleteFetching,
    logoutUser,
    reSendValidationFetching,
    reSendValidation,
    cleanReSendValidation,
    getIsValidDocuments,
    valid,
    cleanIsValidDocuments,
    validation,
    cleanExternalUser
}: MyAccountProps) => {
    const toast = useToast();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingDeleteButton, setLoadingDeleteButton] = useState<boolean>(false);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [imageProfile, setImageProfile] = useState<string>("");
    const [newImageProfile, setNewImageProfile] = useState<any>({ path: "", file: "" });
    const [modalValidation, setModalValidation] = useState<any>({ open: false, type: "" });

    const [name, setName] = useState<any>({ value: "", error: "" });
    const [lastName, setLastName] = useState<any>({ value: "", error: "" });
    const [email, setEmail] = useState<any>({ value: "", error: "" });
    const [street, setStreet] = useState<any>({ value: "", error: "" });
    const [streetNumber, setStreetNumber] = useState<any>({ value: "", error: "" });
    const [rut, setRut] = useState<any>({ value: "", error: "" });
    const [birthday, setBirthday] = useState<any>({ value: "", error: "" });
    const [phoneValue, setPhoneValue] = useState<any>({ code: "", number: "", error: "" });
    const [civilSelected, setCivilSelected] = useState<any>({ value: "", error: "" });
    const [genderSelected, setGenderSelected] = useState<any>({ value: "", error: "" });
    const [countrySelected, setCountrySelected] = useState<any>({ value: "", error: "" });
    const [newResidence, setNewResidence] = useState<boolean>(false);
    const [regionSelected, setRegionSelected] = useState<any>({ value: "", error: "" });
    const [citySelected, setCitySelected] = useState<any>({ value: "", error: "" });
    const [communeSelected, setCommuneSelected] = useState<any>({ value: "", error: "" });

    const handleClickReSendValidation = () => {
        reSendValidation();
    }

    const handleChangeImage = (event: any) => {
        const file = event.target.files[0];
        new Compressor(file, {
            success(result) {
                setNewImageProfile({
                    path: URL.createObjectURL(result),
                    file: result
                });
            },
            error(err) {
                toast("Ha ocurrido un error al procesar la imagen");
            }
        });
    }

    const handleChangeRut = (value: any) => {
        const formatValue = formatRut(value);
        if (!validateRut(formatValue)) {
            return setRut({ value: formatValue, error: "Ingresa un rut válido" });
        }
        setRut({ ...rut, value: formatValue });
    }

    const handleChangeBirthday = (value: any) => {
        setBirthday({ ...birthday, value: value });
    }

    const handleChangePhone = (value: any, type: string) => {
        if (type === "code") {
            setPhoneValue({ ...phoneValue, code: value });
        } else {
            setPhoneValue({ ...phoneValue, number: value });
        }
    }

    const handleChangeCivil = (name: string) => {
        setCivilSelected({ ...civilSelected, value: name });
    }

    const handleChangeGender = (name: string) => {
        setGenderSelected({ ...genderSelected, value: name });
    }

    const handleChangeCountry = (name: string) => {
        setCountrySelected({ ...countrySelected, value: name });
    }

    const handleChangeRegion = (id: string) => {
        const region = regions.data.find(({ region_id }: any) => region_id === id);
        setRegionSelected({ ...regionSelected, value: { id: region?.region_id, name: region?.name } });
        getCities(id);
        setNewResidence(true);
        setCitySelected({ ...citySelected, value: "" });
        setCommuneSelected({ ...communeSelected, value: "" });
    }

    const handleChangeCity = (id: string) => {
        const city = cities.data.find(({ city_id }: any) => city_id === id);
        setCitySelected({ ...citySelected, value: { id: city?.city_id, name: city?.name } });
        getCommunes(id);
        setNewResidence(true);
        setCommuneSelected({ ...communeSelected, value: "" });
    }

    const handleChangeCommune = (id: string) => {
        const commune = communes.data.find(({ commune_id }: any) => commune_id === id);
        setCommuneSelected({ ...communeSelected, value: { id: commune?.commune_id, name: commune?.name } });
        setNewResidence(true);
    }

    const updateDataUser = () => {
        const phone = {
            code: phoneValue?.code,
            number: phoneValue?.number,
        }

        let picture = user?.picture;
        if (postImage?.data.length !== 0) {
            picture = postImage?.data?.path;
        }

        editUserRequest({
            ...user,
            name: name?.value,
            last_name: lastName?.value,
            email: email?.value,
            legal_id: formatRut(rut?.value),
            phone: phone,
            date_of_birth: birthday?.value,
            picture: picture,
            country: countrySelected?.value,
            gender: genderSelected?.value,
            civil_status: civilSelected?.value,
            residence: user?.residence,
            address: `${street?.value} ${streetNumber?.value}`
        });
    }

    const handleSave = () => {
        if (!name?.value) return setName({ ...name, error: "Debes ingresar un nombre" })
        if (!lastName?.value) return setLastName({ ...lastName, error: "Debes ingresar un apellido" })
        if (!email?.value) return setEmail({ ...email, error: "Debes ingresar un email" })
        if (!birthday?.value) return setBirthday({ ...birthday, error: "Debes ingresar una fecha de nacimiento" })
        if (!rut?.value) return setRut({ ...rut, error: "Debes ingresar un rut" })
        if (!phoneValue?.code) { return setPhoneValue({ ...phoneValue, error: "Debes ingresar un pais" }) } else setPhoneValue({ ...phoneValue, error: "" });
        if (!phoneValue?.number) { return setPhoneValue({ ...phoneValue, error: "Debes ingresar un telefono" }) } else setPhoneValue({ ...phoneValue, error: "" });
        if (!civilSelected?.value) return setCivilSelected({ ...civilSelected, error: "Debes seleccionar un estado civil" })
        if (!genderSelected?.value) return setGenderSelected({ ...genderSelected, error: "Debes seleccionar un genero" })
        if (!regionSelected?.value) return setRegionSelected({ ...regionSelected, error: "Debes seleccionar una región" });
        if (!street?.value) return setStreet({ ...street, error: "Debes ingresar una calle" });
        if (!streetNumber?.value) return setStreetNumber({ ...streetNumber, error: "Debes ingresar un numero" });
        if (regionSelected?.value) {
            if (!citySelected?.value) {
                setCitySelected({ ...citySelected, error: "Debes seleccionar una ciudad" });
                if (!communeSelected?.value) return setCommuneSelected({ ...communeSelected, error: "Debes seleccionar una comuna" });
            }
            if (!communeSelected?.value) return setCommuneSelected({ ...communeSelected, error: "Debes seleccionar una comuna" });
        }

        setLoading(true);

        if (!newImageProfile?.path && user?.residence?.region_id === regionSelected?.value?.id && user?.residence?.city_id === citySelected?.value?.id && user?.residence?.commune_id === communeSelected?.value?.id) {
            updateDataUser();
        }

        if (newImageProfile?.path) {
            const formData = new FormData();
            formData.append("file", newImageProfile?.file, newImageProfile?.file?.name);
            postUserImage(formData, token);
        }

        if (newResidence) {
            residence.region_id = regionSelected?.value?.id;
            residence.region_name = regionSelected?.value?.name;
            residence.city_id = citySelected?.value?.id;
            residence.city_name = citySelected?.value?.name;
            residence.commune_id = communeSelected?.value?.id;
            residence.commune_name = communeSelected?.value?.name;

            patchResidence(residence);
        }
    }

    const handleDelete = () => {
        if (!(user?.profile?.code === "seller")) {
            setOpenDeleteModal(true);
        }
    }

    const regionsNormalized: any = useMemo(() => {
        return regions.data && regions.data.map((region: any) => (
            {
                id: region.region_id,
                name: region.name
            }
        ))
    }, [regions.data]);

    const citiesNormalized: any = useMemo(() => {
        return cities.data && cities.data.map((city: any) => (
            {
                id: city?.city_id,
                name: city?.name
            }
        ))
    }, [cities.data]);

    const communesNormalized: any = useMemo(() => {
        return communes.data && communes.data.map((commune: any) => (
            {
                id: commune?.commune_id,
                name: commune?.name,
                cityId: commune?.city_id
            }
        ))
    }, [communes.data]);

    const countriesNormalized: any = useMemo(() => {
        return countries && countries.map((country: any) => (
            {
                id: country?.dial_code,
                name: country?.name
            }
        ))
    }, [countries]);

    useEffect(() => {
        if (valid?.fetching === "failure") {
            toast("Ha ocurrido un error al calcular la validación de su documentación");
            cleanIsValidDocuments();
        }
        if (valid?.fetching === "success") {
            cleanIsValidDocuments();

            const someNotCompleted = valid?.data && Object.values(valid?.data?.completed).some((item: any) => !item);
            if (validation?.waiting && someNotCompleted && valid?.data?.completed?.residence) {
                setModalValidation({ open: true, type: "documentsFault" });
                return;
            }
            if (validation?.waiting && !someNotCompleted) {
                setModalValidation({ open: true, type: "" });
            }
        }
    }, [valid?.fetching]);

    useEffect(() => {
        if (reSendValidationFetching === "failure") {
            toast("Ha ocurrido un error al enviar el email de verificación");
            cleanReSendValidation();
        }
        if (reSendValidationFetching === "success") {
            toast("Se te ha enviado un email de verificación");
            cleanReSendValidation();
        }
    }, [reSendValidationFetching]);

    useEffect(() => {
        if (deleteFetching === "loading") {
            setLoadingDeleteButton(true);
        }
        if (deleteFetching === "failure") {
            setLoadingDeleteButton(false);
            toast("Ha ocurrido un error al eliminar el usuario");
        }
        if (deleteFetching === "success") {
            toast("Usuario eliminado correctamente");
            setLoadingDeleteButton(false);
            setOpenDeleteModal(false);
            cleanExternalUser();
            googleLogout();
            logoutUser();
        }
    }, [deleteFetching]);

    useEffect(() => {
        if (postImage?.fetching === "failure") {
            toast("Ha ocurrido un error al actualizar la foto de perfil");
            setLoading(false);
            cleanPostImage();
        }
        if (postImage?.fetching === "success" && newResidence) { // Si se actualiza la imagen y hay residencia nueva
            setNewImageProfile({ path: "", file: "" });
            cleanPostImage();
        }
        if (postImage?.fetching === "success" && !newResidence) { // Si se actualiza la imagen y NO hay residencia nueva
            updateDataUser();
            cleanPostImage();
        }
    }, [postImage?.fetching]);

    useEffect(() => {
        if (fetchingPatchResidence === "failure") {
            toast("Ha ocurrido un error al actualizar la residencia");
            setLoading(false);
            cleanProfileFetching();
        }
        if (fetchingPatchResidence === "success" && newImageProfile?.path.length !== 0) { // Si se actualizo la residencia y hay foto de perfil para subir
            setNewResidence(false);
        }
        if (fetchingPatchResidence === "success" && newImageProfile?.path.length === 0) { // Si se actualizo la residencia y NO hay foto de perfil para subir
            updateDataUser();
        }
    }, [fetchingPatchResidence]);

    useEffect(() => {
        if (postImage?.fetching === "success" && fetchingPatchResidence === "success" && newImageProfile?.path.length === 0 && !newResidence) {
            updateDataUser();
        }
    }, [newResidence, newImageProfile]);


    useEffect(() => {
        if (editUserReducer === "loading") {
            setLoading(true);
        }
        if (editUserReducer === "success") {
            setLoading(false);
            toast("Cambios realizados correctamente");
            getIsValidDocuments();
            cleanProfileFetching();
        }
        if (editUserReducer === "failure") {
            setLoading(false);
            toast("Ha ocurrido un error");
            cleanProfileFetching();
        }
    }, [editUserReducer]);

    useEffect(() => {
        setName({ ...name, value: user?.name ? user?.name : "" });
        setLastName({ ...lastName, value: user?.last_name ? user?.last_name : "" });
        setEmail({ ...email, value: user?.email ? user?.email : "" });
        setRut({ ...rut, value: user?.legal_id ? formatRut(user?.legal_id) : "" });
        setBirthday({ ...birthday, value: user?.date_of_birth ? user?.date_of_birth : "" });
        setPhoneValue({ code: user?.phone?.code, number: user?.phone?.number, error: "" });
        setCivilSelected({ ...civilSelected, value: user?.civil_status ? user?.civil_status : "Soltero" });
        setGenderSelected({ ...genderSelected, value: user?.gender ? user?.gender : "Masculino" });
        setCountrySelected({ ...countrySelected, value: user?.country ? user?.country : "" });
        setRegionSelected({ ...regionSelected, value: user?.residence?.region_id ? { id: user?.residence?.region_id, name: user?.residence?.region_name } : "" });
        setCitySelected({ ...citySelected, value: user?.residence?.city_id ? { id: user?.residence?.city_id, name: user?.residence?.city_name } : "" });
        setCommuneSelected({ ...communeSelected, value: user?.residence?.commune_id ? { id: user?.residence?.commune_id, name: user?.residence?.commune_name } : "" });
        setStreet({ ...street, value: user?.address ? user?.address.split(" ").slice(0, -1).join(" ") : "" });
        setStreetNumber({ ...street, value: user?.address ? user?.address.split(" ").pop() : "" });
    }, [user]);

    useEffect(() => {
        getIsValidDocuments();
        getRegions();
        if (user?.picture) {
            setImageProfile(user?.picture);
        }
        if (user?.residence) {
            getCities(user?.residence.region_id);
            getCommunes(user?.residence.city_id);
        }
    }, []);

    return (
        <ProfileCard>
            <ModalValidationDocuments open={modalValidation?.open} setOpen={setModalValidation} type={modalValidation?.type} />
            <Modal open={openDeleteModal} variant="white" className={styles.deleteModalContainer}>
                <p>¿Seguro que quieres ELIMINAR tu cuenta?</p>
                <div className={styles.buttons}>
                    <ButtonShared
                        onPress={() => setOpenDeleteModal(false)}
                        title="Atras"
                        tertiary
                        size="small"
                    />
                    <ButtonShared
                        onPress={() => deleteUser(user?._id)}
                        title="Eliminar"
                        primary
                        size="small"
                        loading={loadingDeleteButton}
                    />
                </div>
            </Modal>
            <div className={styles.myAccount}>
                <div className={styles.myAccountContent}>
                    <div className={styles.imageContainer}>
                        {
                            regions?.fetching === "loading"
                                ? <Skeleton animation="wave" variant="circular" className={`${styles.imageProfile} ${styles.skeleton}`} />
                                : <label htmlFor="imageProfile" className={styles.imageProfile}>
                                    <div className={styles.icon}>
                                        <img src={images.camera} alt="Icon" />
                                    </div>
                                    <input type="file" name="imageProfile" accept="image/*" onChange={handleChangeImage} />
                                    <img src={newImageProfile?.path ? newImageProfile?.path : (imageProfile ? imageProfile : images.profile)} alt="Imagen perfil" />
                                </label>
                        }
                        <div className={styles.data}>
                            {
                                regions?.fetching === "loading"
                                    ? <Skeleton animation="wave" variant="text" className={`${styles.name} ${styles.skeleton}`} />
                                    : <p className={styles.name}>{`${user?.name} ${user?.last_name}`}</p>
                            }
                            {
                                regions?.fetching === "loading"
                                    ? <Skeleton animation="wave" variant="text" className={`${styles.email} ${styles.skeleton}`} />
                                    : <p className={styles.email}>{user?.email}</p>
                            }
                            {
                                !user?.email_verification?.verified
                                    ?
                                    regions?.fetching === "loading"
                                        ? <Skeleton animation="wave" variant="text" className={`${styles.validateEmail} ${styles.skeleton}`} />
                                        : <p className={styles.validateEmail} onClick={handleClickReSendValidation}>Aún no validaste tu mai</p>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    <div className={styles.line}></div>
                    <div className={styles.infoSegment}>
                        <div className={styles.title}>
                            <p>Datos personales</p>
                        </div>
                        <div className={styles.grid}>
                            <TextFieldShared
                                label="Nombre"
                                name="nameInput"
                                value={name?.value}
                                checker="onlyLetters"
                                className={styles.half}
                                onChange={(value: any) => setName({ ...name, value: value })}
                                loading={regions?.fetching === "loading"}
                                error={name?.error}
                            />
                            <TextFieldShared
                                label="Apellido"
                                name="lastNameInput"
                                checker="onlyLetters"
                                value={lastName?.value}
                                className={styles.half}
                                onChange={(value: any) => setLastName({ ...lastName, value: value })}
                                loading={regions?.fetching === "loading"}
                                error={lastName?.error}
                            />
                            <TextFieldShared
                                label="RUT"
                                name="rutInput"
                                value={rut?.value}
                                className={styles.half}
                                onChange={handleChangeRut}
                                loading={regions?.fetching === "loading"}
                                error={rut?.error}
                            />
                            <PhoneInput
                                label="Telefono"
                                numberValue={phoneValue.number}
                                codeValue={phoneValue.code}
                                onChange={handleChangePhone}
                                error={phoneValue?.error}
                                className={styles.half}
                            />
                        </div>
                    </div>
                    {
                        !(user?.profile?.code === "seller") &&
                        <>
                            <div className={styles.line}></div>
                            <div className={styles.infoSegment}>
                                <div className={styles.title}>
                                    <p>Información adicional</p>
                                </div>
                                <div className={styles.grid}>
                                    <TextFieldShared
                                        label="Email"
                                        name="email"
                                        value={email?.value}
                                        disabled
                                        className={styles.half}
                                        loading={regions?.fetching === "loading"}
                                    />
                                    <DatePickerShared
                                        name="birthday"
                                        label="Fecha de nacimiento"
                                        value={birthday?.value}
                                        onChange={handleChangeBirthday}
                                        inputFormat="DD/MM/YYYY"
                                        className={styles.half}
                                        loading={regions?.fetching === "loading"}
                                        error={birthday?.error}
                                    />
                                    <SelectShared
                                        label="Estado civil"
                                        name="civilInput"
                                        value={civilSelected?.value}
                                        className={styles.half}
                                        onChange={handleChangeCivil}
                                        options={civilOptions}
                                        loading={regions?.fetching === "loading"}
                                        keyValue="name"
                                        error={civilSelected?.error && civilSelected?.error}
                                    />
                                    <SelectShared
                                        label="Genero"
                                        name="genderInput"
                                        value={genderSelected?.value}
                                        className={styles.half}
                                        onChange={handleChangeGender}
                                        options={genderOptions}
                                        loading={regions?.fetching === "loading"}
                                        keyValue="name"
                                        error={genderSelected?.error && genderSelected?.error}
                                    />
                                </div>
                            </div>
                        </>
                    }
                    <div className={styles.line}></div>
                    <div className={styles.infoSegment}>
                        <div className={styles.title}>
                            <p>Ubicación</p>
                            {
                                regions?.fetching !== "loading" && valid?.data && valid?.data?.completed?.residence &&
                                <div className={styles.check}>
                                    <CheckIcon className={styles.icon} />
                                    <p>Verificado</p>
                                </div>
                            }
                        </div>
                        <div className={styles.grid}>
                            <SelectShared
                                label="Pais"
                                name="country"
                                value={countrySelected?.value}
                                className={styles.half}
                                onChange={handleChangeCountry}
                                options={countriesNormalized}
                                loading={regions?.fetching === "loading"}
                                keyValue="name"
                                error={countrySelected?.error && countrySelected?.error}
                            />
                            <div className={`${styles.input} ${styles.half}`}>
                                <SelectShared
                                    label="Región"
                                    name="region"
                                    value={regionSelected?.value && regionSelected?.value?.id}
                                    onChange={handleChangeRegion}
                                    options={regionsNormalized}
                                    loading={regions?.fetching === "loading"}
                                    error={regionSelected?.error && regionSelected?.error}
                                />
                                {
                                    regions?.fetching !== "loading" && valid?.data && valid?.data?.completed?.residence &&
                                    <CheckIcon className={styles.check} />
                                }
                            </div>
                            <div className={`${styles.input} ${styles.half}`}>
                                <SelectShared
                                    label="Ciudad"
                                    name="city"
                                    value={citySelected?.value && citySelected?.value?.id}
                                    onChange={handleChangeCity}
                                    options={citiesNormalized}
                                    optionsEmptyMessage="Debes seleccionar una región"
                                    loading={regions?.fetching === "loading"}
                                    error={citySelected?.error && citySelected?.error}
                                />
                                {
                                    regions?.fetching !== "loading" && valid?.data && valid?.data?.completed?.residence &&
                                    <CheckIcon className={styles.check} />
                                }
                            </div>
                            <div className={`${styles.input} ${styles.half}`}>
                                <SelectShared
                                    label="Comuna"
                                    name="commune"
                                    value={communeSelected?.value && communeSelected?.value?.id}
                                    onChange={handleChangeCommune}
                                    options={communesNormalized}
                                    optionsEmptyMessage="Debes seleccionar una ciudad"
                                    loading={regions?.fetching === "loading"}
                                    error={communeSelected?.error && communeSelected?.error}
                                />
                                {
                                    regions?.fetching !== "loading" && valid?.data && valid?.data?.completed?.residence &&
                                    <CheckIcon className={styles.check} />
                                }
                            </div>
                            <div className={`${styles.input} ${styles.half}`}>
                                <TextFieldShared
                                    label="Calle"
                                    name="street"
                                    value={street?.value}
                                    className={styles.half}
                                    onChange={(value: any) => setStreet({ ...street, value: value })}
                                    error={street?.error && street?.error}
                                />
                                {
                                    street?.value !== "" &&
                                    <CheckIcon className={styles.check} />
                                }
                            </div>
                            <div className={`${styles.input} ${styles.half}`}>
                                <TextFieldShared
                                    label="Número"
                                    name="streetNumber"
                                    value={streetNumber?.value}
                                    className={styles.half}
                                    onChange={(value: any) => setStreetNumber({ ...streetNumber, value: value })}
                                    error={streetNumber?.error && streetNumber?.error}
                                />
                                {
                                    streetNumber.value !== "" &&
                                    <CheckIcon className={styles.check} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.buttons}>
                        <ButtonShared
                            onPress={handleSave}
                            title="Guardar cambios"
                            primary
                            loading={regions?.fetching === "loading" || loading}
                        />
                        {
                            !(user?.profile?.code === "seller") &&
                            <ButtonShared
                                onPress={handleDelete}
                                title="Eliminar cuenta"
                                disabled={regions?.fetching === "loading" || loading}
                            />
                        }
                    </div>
                </div>
            </div>
        </ProfileCard>
    )
};
export default MyAccount;