import { memo, useEffect, useState } from 'react';
import styles from './financing.module.scss';
import { useNavigate } from "react-router-dom";
import { FinancingProps } from '../../../interfaces/pages';
import { addPointsInText } from '../../../helpers/app';
import BuyModal from '../../../components/BuyModal';
import Button from '../../../components/Button';
import ToggleButtonShared from '../../../components/ToggleButton/ToggleButton';
import TextFieldShared from '../../../components/TextField/TextField';
import { optionsCreditType, optionsFees } from '../../../constants/Buy';
import { SimulationDataProps } from '../../../interfaces';
import { useToast } from '../../../helpers/hooks';
import { CreditTypesBuy, FeesTypes } from '../../../interfaces/types';

const Financing = ({
    user,
    setStep,
    step,
    method,
    publication,
    creditSimulation,
    getCreditSimulation,
    cleanCreditSimulation,
    setFinancingRequest,
    financingReducer
}: FinancingProps) => {
    const toast = useToast();
    const navigate = useNavigate();
    const [creditTypeSelected, setCreditTypeSelected] = useState<CreditTypesBuy>("conventional");
    const [footValue, setFootValue] = useState<number>(0);
    const [feesSelected, setFeesSelected] = useState<FeesTypes>(24);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);

    const handleChangeCreditType = (value: any) => {
        setCreditTypeSelected(value);
        setFeesSelected(24);
    }

    const handlePressBack = () => {
        cleanCreditSimulation();
        setStep(step - 1);
        navigate(`/buy/credit_request`);
    }

    const handleNextStep = () => {
        setLoading(true);
        if (creditTypeSelected == "intelligent" && (((publication?.data?.other_prices?.offer_price ?? publication?.data?.price) / 100) * 40) < footValue) {
            setLoading(false);
            return toast("El valor de pie debe ser menor al 40%");
        }
        if (creditTypeSelected == "intelligent" && (((publication?.data?.other_prices?.offer_price ?? publication?.data?.price) / 100) * 20) > footValue) {
            setLoading(false);
            return toast("El valor de pie debe ser mayor al 20%");
        }
        if (creditTypeSelected == "conventional" && (((publication?.data?.other_prices?.offer_price ?? publication?.data?.price) / 100) * 20) > footValue) {
            setLoading(false);
            return toast("El valor de pie debe ser mayor al 20%");
        }

        if (creditTypeSelected == "conventional" && (((publication?.data?.other_prices?.offer_price ?? publication?.data?.price) / 100) * 90) < footValue) {
            setLoading(false);
            return toast("El valor de pie debe ser menor al 90%");
        }

        setFinancingRequest(
            (publication?.data?.other_prices?.offer_price ?? publication?.data?.price),
            creditTypeSelected,
            footValue,
            feesSelected,
        );

        const bodyCreditSimulation: SimulationDataProps = {
            publication: publication?.data?._id,
            simulation_data: {
                liquid_income: user?.labor_data?.liquid_income,
                credit: {
                    vehicle_price: publication?.data?.other_prices?.offer_price ?? publication?.data?.price,
                    effective_foot: footValue,
                    installments_number: feesSelected?.toString(),
                    vehicle_status: "U",
                    credit_type: creditTypeSelected === "conventional" ? "C" : "I"
                },
                vehicle: {
                    brand_id: publication?.articles?.data?.brand_id,
                    model_id: publication?.articles?.data?.model_id,
                    version_id: publication?.articles?.data?.version_id,
                    year: publication?.articles?.data?.year
                }
            }
        }

        getCreditSimulation(bodyCreditSimulation);
    }

    useEffect(() => {
        if (creditSimulation.fetching === "loading") {
            setLoading(true);
        }
        if (creditSimulation.fetching === "failure") {
            setLoading(false);
            toast(creditSimulation.error ? creditSimulation.error : "Ha ocurrido un error");
            cleanCreditSimulation();
        }
        if (creditSimulation.fetching === "success") {
            setLoading(false);
            cleanCreditSimulation();
            setStep(step + 1);
            navigate("/buy/additional_costs");
        }
    }, [creditSimulation.fetching]);

    useEffect(() => {
        if (footValue.toString() !== "0") {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [footValue]);

    useEffect(() => {
        setCreditTypeSelected(financingReducer?.creditType);
        setFootValue(financingReducer?.footValue);
        setFeesSelected(financingReducer?.fees);
    }, [financingReducer]);

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.style.overflowY = "auto";
        if (publication?.data && financingReducer?.footValue === 0) {
            setFootValue((Math.ceil(((publication?.data?.other_prices?.offer_price ?? publication?.data?.price) / 100) * 20)));
        }
    }, []);

    return (
        <BuyModal title="Financiamiento" onBack={handlePressBack} step={step} method={method}>
            <div className={styles.financing}>
                <div className={`${styles.item} ${styles.priceCar}`}>
                    <p className={styles.title}>Precio del vehículo</p>
                    <div className={styles.content}>
                        <p>
                            ${addPointsInText(publication?.data?.other_prices?.offer_price ?? publication?.data?.price)}
                        </p>
                    </div>
                </div>
                <div className={`${styles.item} ${styles.revenues}`}>
                    <p className={styles.title}>Tipo de crédito</p>
                    <div className={styles.content}>
                        <ToggleButtonShared
                            type="group"
                            value={creditTypeSelected}
                            onPress={handleChangeCreditType}
                            exclusive
                        >
                            {optionsCreditType}
                        </ToggleButtonShared>
                    </div>
                </div>
                <div className={`${styles.item} ${styles.fees}`}>
                    <p className={styles.title}>Valor de pie</p>
                    <p className={styles.subtitle}>{creditTypeSelected === "intelligent" ? "Recuerda que el valor del pie no debe ser inferior al 20% ni superior al 40% del monto total" : "Recuerda que el valor del pie no debe ser inferior al 20% ni superior al 90% del monto total"}</p>
                    <TextFieldShared
                        variant="borderless"
                        name="footValue"
                        value={footValue}
                        onChange={(value: any) => setFootValue(value)}
                        autoFocus={true}
                        className={styles.input}
                    />
                    <p className={styles.subtitle}>(cantidad de meses/cuotas)</p>
                    <div className={styles.content}>
                        {
                            optionsFees && optionsFees.map((option: any, index: number) => {
                                if (creditTypeSelected === "intelligent" && (option?.value === 12)) {
                                    return;
                                }
                                return (
                                    <ToggleButtonShared
                                        key={index}
                                        type="single"
                                        value={option?.value}
                                        onPress={(value: any) => setFeesSelected(value)}
                                        className={styles.option}
                                        selected={(feesSelected === option?.value)}
                                    >
                                        <p>{`${option?.label?.value} ${option?.label?.text}`}</p>
                                    </ToggleButtonShared>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <Button
                onPress={handleNextStep}
                title="Siguiente"
                primary
                disabled={disabled}
                loading={loading}
                className={styles.submitButton}
            />
        </BuyModal>
    )
}

export default memo(Financing);